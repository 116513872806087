import React, { useEffect } from "react";
import BaseComponent from "../base/Base_component";
import "./Team_component.scss";

function TeamComponent() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <BaseComponent>
        <div className="teamContainer">
          <h3>Team</h3>
          <h5>a</h5>
          <h4>
            Any company can offer digital media services.What sets any agency
            apart from the rest of the clans is the caliber of people working
            together to build their brand.Meet the people who make it happen.
          </h4>
          <div className="teamContainer">
            <div className="teamList">
              <img
                style={{ borderRadius: "50%" }}
                src="team1.jpg"
                alt="team"
              />
              <h1>RJ Suresh</h1>
              <p>Head of operations & Co Founder</p>
            </div>
            <div className="teamList">
              <img src="team2.png" alt="team" />
              <h1>R Prasanth</h1>
              <p>Content Head & Founder</p>
            </div>
            <div className="teamList">
              <img style={{ borderRadius: "50%" }} src="team3.jpg" alt="team" />
              <h1>Mohesh </h1>
              <p>Senior Marketing Analyst</p>
            </div>
            <div className="teamList">
              <img style={{ borderRadius: "50%" }} src="team4.jpg" alt="team" />
              <h1>Aarthi </h1>
              <p>Public Relations Head</p>
            </div>
            <div className="teamList">
              <img
                style={{ borderRadius: "50%" }}
                src="team5.jpeg"
                alt="team"
              />
              <h1> Arul Suber </h1>
              <p>Content Analyst</p>
            </div>
            <div className="teamList">
              <img
                style={{ borderRadius: "50%" }}
                src="team6.jpg"
                alt="team"
              />
              <h1> MadhuRaj </h1>
              <p>Digital Marketing Analyst</p>
            </div>
            <div className="teamList">
              <img
                style={{ borderRadius: "50%" }}
                src="team7.jpg"
                alt="team"
              />
              <h1> Mohanraj R </h1>
              <p>Senior Content Lead</p>
            </div>
          </div>
        </div>
      </BaseComponent>
    </React.Fragment>
  );
}

export default TeamComponent;
