import React from "react";
import "./Footer_component.scss";
import {Link} from 'react-router-dom';

function FooterComponent() {
  return (
    <React.Fragment>
      <div className="bgConatiner">
        <div className="footerContainer">
          <div className="navbar">
            <ul>
             <Link to="/"> <li>Home</li></Link>
             <Link to="/about">  <li>About us</li></Link>
             <Link to="/project"> <li>Project</li></Link>
             <Link to="/services"> <li>Services</li></Link>
             <Link to="/team"><li>Team</li></Link>
            </ul>
          </div>
          <div className="socialLink">
            <ul>
              <a href="https://www.instagram.com/madrasdigitalmedia/">
                <li>
                  <i class="fa fa-instagram"></i>Instagram
                </li>
              </a>
              <a href="https://m.facebook.com/madrasdigitalmedia/">
              <li>
                <i class="fa fa-facebook-square"></i>Facebook
              </li>
              </a>
              <a href="https://twitter.com/digital_madras?s=08">
              <li>
                <i class="fa fa-twitter-square"></i>Twitter
              </li>
              </a>
              <a href="https://api.whatsapp.com/send?phone=918012432838&text=Hello%20Madras%20Digital%20Media!!">
              <li>
              <i class="fa fa-whatsapp"></i>Whatsapp
              </li>
              </a>
            </ul>
          </div>
          <div className="address">
            <ul>
              <li>Madras Digital Media</li>
              <li>Email: madrasdigitalmedia@gmail.com</li>
              <li>Phone: 8012432838, 9094443791</li>
              <li>Address:  Chennai</li>
            </ul>
          </div>
        </div>
        <h5>© 2020 copyright:Madrasdigitalmedia</h5>
        <a href="https://oddu.in">
          {" "}
          <h4>Built with <i style={{color:"tomato"}} class="fa fa-heart"></i> by Oddu.in</h4>
        </a>
      </div>
    </React.Fragment>
  );
}

export default FooterComponent;
